body {
  margin: 0;
  font-family: 'Roboto', 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
  width: 100%;
  background-color: #070707;
}

a {
  text-decoration: none;
}

svg {
  width: 40px;
  height: 40px;
  color: #ffffff;
  padding: 5px;
  margin: 0px 15px 0 0;
}
